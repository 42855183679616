var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import { ScrollableArea } from '../../../ts/components/ScrollableArea';
import { FrontPage } from '../../pages/root/front-page';
export class Header extends Component {
    constructor() {
        super('Header', true);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('header a').on('click', (event, link) => {
                event.preventDefault();
                $('.content').addClass('d-none');
                $(link.attr('href')).removeClass('d-none');
                $('header a').removeClass('active');
                link.addClass('active');
                $('#title').html('&nbsp;' + link.text() + '&nbsp;');
                FrontPage.appear(true);
                ScrollableArea.activeAreaIndex = Array.from(link.get(0).parentNode.children).indexOf(link.get(0));
                ScrollableArea.recalculate();
            });
            this.success();
        });
    }
}
