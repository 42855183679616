// Autogenerated by Hanako
import { Debug } from '../../views/ts/components/Debug';
import { LazyLoader } from '../../views/ts/components/LazyLoader';
import { ScrollableArea } from '../../views/ts/components/ScrollableArea';
import { Header } from '../../views/twig/components/Header/index';
import { FrontPage } from '../../views/twig/pages/root/front-page/index';
(new Debug()).init();
(new LazyLoader()).init();
(new ScrollableArea()).init();
(new Header()).init();
(new FrontPage()).init();
