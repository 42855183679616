var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class LazyLoader extends Component {
    constructor() {
        super('LazyLoader', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            LazyLoader.images = $('*[data-hw-src]');
            LazyLoader.backgroundImages = $('*[data-hw-background-image]');
            $(window).on('scroll', () => {
                LazyLoader.checkImageVisibility();
            });
            LazyLoader.checkImageVisibility();
            this.success();
        });
    }
    static checkImageVisibility() {
        LazyLoader.images.each((image) => {
            if (image.viewportPosition().y < $(window).height() + LazyLoader.tolerence && image.data('backgroundLoaded') != 'true') {
                image.attr('src', image.data('hwSrc'));
                image.data('backgroundLoaded', 'true');
            }
        });
        LazyLoader.backgroundImages.each((image) => {
            if (image.viewportPosition().y < $(window).height() + LazyLoader.tolerence && image.data('backgroundLoaded') != 'true') {
                image.css('background-image', 'url(\'' + image.data('hwBackgroundImage') + '\')');
                image.data('backgroundLoaded', 'true');
            }
        });
    }
}
LazyLoader.tolerence = -100;
