var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import SimpleBar from 'simplebar';
export class ScrollableArea extends Component {
    constructor() {
        super('ScrollableArea', true);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.initialize();
            // Fallback for failed init
            setTimeout(() => {
                ScrollableArea.recalculate();
            }, 500);
            this.success();
        });
    }
    initialize() {
        $('.scrollable-area').forEach((scrollableArea) => {
            ScrollableArea.simpleBars.push(new SimpleBar(scrollableArea, { autoHide: false }));
        });
        $('body').on('keydown', (event) => {
            if (event.key == 'ArrowUp')
                this.scrollUp();
            if (event.key == 'ArrowDown')
                this.scrollDown();
        });
        $('.btn-scroll-up').on('click', (event) => {
            this.scrollUp();
        });
        $('.btn-scroll-down').on('click', (event) => {
            this.scrollDown();
        });
        $(window).on('resize', () => {
            ScrollableArea.toggleScrollButtonsVisibility();
        });
    }
    static recalculate() {
        ScrollableArea.simpleBars[ScrollableArea.activeAreaIndex].recalculate();
        ScrollableArea.toggleScrollButtonsVisibility();
    }
    static toggleScrollButtonsVisibility() {
        const simpelBar = ScrollableArea.simpleBars[ScrollableArea.activeAreaIndex];
        if ($(simpelBar.contentEl).height() >= $(simpelBar.contentWrapperEl).height()) {
            $('.btn-scroll-up, .btn-scroll-down').removeClass('d-none');
        }
        else {
            $('.btn-scroll-up, .btn-scroll-down').addClass('d-none');
        }
    }
    calculateLineHeight() {
        return $('header').height(); //(Math.round($('.scrollable-content').eq(ScrollableArea.activeAreaIndex).height() * 10) / 10) / $('header').length;
    }
    floorScrollTop() {
        return Math.floor(ScrollableArea.simpleBars[ScrollableArea.activeAreaIndex].contentWrapperEl.scrollTop / this.calculateLineHeight()) * this.calculateLineHeight();
    }
    scrollUp() {
        const lineHeight = this.calculateLineHeight();
        ScrollableArea.simpleBars[ScrollableArea.activeAreaIndex].contentWrapperEl.scrollTop = this.floorScrollTop() - lineHeight * 3;
    }
    scrollDown() {
        const lineHeight = this.calculateLineHeight();
        console.log(lineHeight, $('header').height());
        ScrollableArea.simpleBars[ScrollableArea.activeAreaIndex].contentWrapperEl.scrollTop = this.floorScrollTop() + lineHeight * 3;
    }
}
ScrollableArea.simpleBars = [];
ScrollableArea.activeAreaIndex = 0;
