var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class FrontPage extends Component {
    constructor() {
        super('FrontPage', true);
        this.filters = ['Tous', 'Interface', 'Animation', 'Site web', 'Boutique'];
        this.filterIndex = 0;
        this.colorSchems = [
            {
                background: '#0000AA',
                foreground: '#fff',
                altBackground: '#AAA',
                altForeground: '#000',
                hoverBackground: '#00AAAA',
                hoverForeground: '#000',
                accent: '#00AAAA',
                inactive: '#5555FF'
            },
            {
                background: '#000',
                foreground: '#fff',
                altBackground: '#fff',
                altForeground: '#000',
                hoverBackground: '#000',
                hoverForeground: '#fff',
                accent: '#fff',
                inactive: '#555555'
            },
            {
                background: '#fff',
                foreground: '#000',
                altBackground: '#000',
                altForeground: '#fff',
                hoverBackground: '#fff',
                hoverForeground: '#000',
                accent: '#000',
                inactive: '#AAAAAA'
            },
            {
                background: '#55FFFF',
                foreground: '#000',
                altBackground: '#0000AA',
                altForeground: '#fff',
                hoverBackground: '#FFFF55',
                hoverForeground: '#000',
                accent: '#FFFF55',
                inactive: '#00AAAA'
            },
            {
                background: '#AAA',
                foreground: '#000',
                altBackground: '#000',
                altForeground: '#AAA',
                hoverBackground: '#AA0000',
                hoverForeground: '#AAA',
                accent: '#AA0000',
                inactive: '#555555'
            }
        ];
        this.colorSchemIndex = 0;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('#btn-toggle-color').on('click', (event) => {
                event.preventDefault();
                this.toggleColor();
            });
            $('#btn-toggle-filter').on('click', (event) => {
                event.preventDefault();
                this.toggleFilter();
            });
            $('body').on('keydown', (event) => {
                if (event.key == 'F2') {
                    event.preventDefault();
                    this.toggleFilter();
                }
                if (event.key == 'F1') {
                    event.preventDefault();
                    this.toggleColor();
                }
            });
            this.toggleColor(Math.floor(Math.random() * this.colorSchems.length));
            FrontPage.appear();
            this.success();
        });
    }
    toggleFilter() {
        $('.project').addClass('d-none');
        this.filterIndex = (this.filterIndex + 1) % this.filters.length;
        let target = this.filters[this.filterIndex] != 'Tous' ? '[data-type="' + this.filters[this.filterIndex] + '"]' : '';
        $('#filter-title').html(this.filters[this.filterIndex] != 'Tous' ? '- ' + this.filters[this.filterIndex] + '&nbsp;' : '');
        $('.project' + target).removeClass('d-none');
    }
    toggleColor(index) {
        if (index !== undefined) {
            this.colorSchemIndex = index;
        }
        else {
            this.colorSchemIndex = (this.colorSchemIndex + 1) % this.colorSchems.length;
        }
        const currentColorSchem = this.colorSchems[this.colorSchemIndex];
        $('body').data('theme', this.colorSchemIndex.toString()).css({
            '--background-color': currentColorSchem.background,
            '--foreground-color': currentColorSchem.foreground,
            '--alt-background-color': currentColorSchem.altBackground,
            '--alt-foreground-color': currentColorSchem.altForeground,
            '--hover-background-color': currentColorSchem.hoverBackground,
            '--hover-foreground-color': currentColorSchem.hoverForeground,
            '--accent-color': currentColorSchem.accent,
            '--inactive-color': currentColorSchem.inactive
        });
    }
    static appear(partial = false) {
        const maxHeight = partial ? $(window).height() - $('header').height() * 2 : $(window).height();
        $('#appear').removeClass('d-none partial').css('height', maxHeight);
        if (partial)
            $('#appear').addClass('partial');
        const delay = partial ? 500 : 0;
        setTimeout(() => {
            let apprearStepsIndex = 0;
            const apprearStepsCount = Math.floor($(window).height() / $('header').height()) + 1;
            const apprearIntervalID = setInterval(() => {
                $('#appear').css('height', maxHeight - apprearStepsIndex * $('header').height());
                if (apprearStepsIndex == apprearStepsCount) {
                    $('#appear').addClass('d-none');
                    clearInterval(apprearIntervalID);
                }
                apprearStepsIndex++;
            }, 15);
        }, delay);
    }
}
