var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import { traverse } from '../helpers/Traverse';
export class Debug extends Component {
    constructor(isDebugEnabled = false) {
        super('Debug', false);
        this.isDebugEnabled = isDebugEnabled;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.setupConsole();
            this.test();
            this.success();
        });
    }
    setupConsole() {
        if (!$('body').hasClass('debug'))
            return;
        let consoleElement = $.parseHTML('<div class="hanako-terminal hanako-terminal-debug"><pre><code id="debug-console"></code></pre></div>');
        $('body').append(consoleElement);
        let consoleConfig = JSON.parse(sessionStorage.getItem('hanako-debug'));
        if (!consoleConfig)
            consoleConfig = { position: 'bottom', status: 'out' };
        consoleElement.addClass(consoleConfig.position);
        consoleElement.addClass(consoleConfig.status);
        $(document).on('keyup', (event) => {
            if (event.altKey && event.code == 'ArrowUp') {
                consoleElement.addClass('top').removeClass('bottom');
                consoleConfig.position = 'top';
                sessionStorage.setItem('hanako-debug', JSON.stringify(consoleConfig));
            }
            if (event.altKey && event.code == 'ArrowDown') {
                consoleElement.addClass('bottom').removeClass('top');
                consoleConfig.position = 'bottom';
                sessionStorage.setItem('hanako-debug', JSON.stringify(consoleConfig));
            }
            if (event.altKey && event.code == 'KeyD') {
                consoleElement.toggleClass('in');
                consoleConfig.status = (consoleElement.hasClass('in')) ? 'in' : 'out';
                sessionStorage.setItem('hanako-debug', JSON.stringify(consoleConfig));
            }
            if (event.altKey && event.code == 'KeyC')
                $('#debug-console').empty();
        });
    }
    test() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!$('body').hasClass('hanako-test'))
                return;
            Debug.print('test', 'Hello world! My name is <span class="key">Hanako</span> and I\'m here to help you.', '', '<br>', true);
            Debug.print('test', 'Please wait for images to load...', '', '<br>', true);
            yield $.imagesLoaded($('img'));
            let images = $('img');
            Debug.print('test', images.length + ' images loaded', '', '<br>', true);
            $('img').each((image) => {
                Debug.print('test', image, '', '', true);
                Debug.print('test', '&#9;' + image.attr('src'), '', '<br>');
            });
            Debug.print('test', 'Everything seems to be okay. <span class="key">Enjoy your day...</span>', '', '<br>', true);
        });
    }
    static print(target, message, before = '', after = '', showTime = false) {
        let time = '';
        if (showTime) {
            const date = new Date();
            const hours = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
            const minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();
            const seconds = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
            time = hours + ':' + minutes + ':' + seconds + '\t';
        }
        if (typeof message === 'object')
            message = traverse(message);
        if (typeof message === 'number')
            message = '<span class="attr">' + message + '</span>';
        $('#' + target + '-console').html(time + before + message + after, true);
    }
    static log(message) {
        if (!$('body').hasClass('debug'))
            return;
        Debug.print('debug', message, '', '<br>', true);
        $('#debug-console').parent().get(0).scrollTop = $('#debug-console').parent().get(0).scrollHeight;
    }
}
